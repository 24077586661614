<template>
  <div>
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <b-tabs v-model="tabIndex">
      <b-tab title="소재 관리">
        <composition></composition>
      </b-tab>
      <b-tab title="상품 특징 관리">
        <feature v-if="loaded[1]"></feature>
<!--        <composition></composition>-->
      </b-tab>
      <b-tab title="상품 특징 매핑">
        <feature-mapping v-if="loaded[2]"></feature-mapping>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Composition from './MasterMetaComposition.vue'
import Feature from './MasterMetaFeature.vue'
import FeatureMapping from './MasterMetaFeatureMapping.vue'

export default {
  name: 'MasterMeta',
  title: '마스터 메타 관리',
  components: {Composition, Feature, FeatureMapping},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
