<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="clearfix mt-1 mb-2">
      <b-button @click="list()" class="mr-1" variant="primary">검색</b-button>
      <b-button class="mr-1" variant="success" @click="showGroupModal">
        소재 그룹 추가
        <b-spinner class="ml-1" small v-if="busy.add"></b-spinner>
      </b-button>
      <b-button class="mr-1" variant="success" @click="showModal">
        소재 추가
        <b-spinner class="ml-1" small v-if="busy.add"></b-spinner>
      </b-button>
<!--      <b-button class="" variant="danger" @click="removeMany">
        선택 삭제
        <b-spinner class="ml-1" small v-if="busy.remove"></b-spinner>
      </b-button>-->
      <div class="pull-right">
        <b-button class="m-1" variant="success" @click="downXlsx()">XLSX</b-button>
      </div>
    </div>

    <c-table id="gmComposition" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 데이터'" @get-more="list(true)">
    </c-table>

    <b-modal title="소재 그룹 추가" size="lg" v-model="modal.addGroup">
      <div class="label-sm">소재 그룹명 (한글)</div>
      <b-input v-model="form.addGroup.name_ko" placeholder="소재명 그룹을 입력해주세요"></b-input>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="success" @click="addGroup()">
          추가
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal title="소재명 추가" size="lg" v-model="modal.add">
      <div class="label-sm">소재명(영문)</div>
      <b-input v-model="form.add.name" placeholder="영문 소재명을 입력해주세요"></b-input>
      <div class="label-sm">소재명(한글)</div>
      <b-input v-model="form.add.name_ko" placeholder="한글 소재명을 입력해주세요"></b-input>
      <div class="label-sm">소재명 그룹</div>
      <v-select v-model="form.add.groupNo" :options="groups" label="name_ko" track-by="no" placeholder="소재 그룹을 선택해주세요."></v-select>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="success" @click="add()">
          추가
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

  </div>
</template>
<script>

import Vue from 'vue';
import {down} from '@/shared/impexp'

export default {
  name: 'MasterMetaComposition',
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
        },
        addGroup: {
          name_ko: '',
        },
        add: {
          groupNo: '',
          name: '',
          name_ko: '',
        }
      },
      form: {
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, addGroup: false, add: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false, addGroup: false},
      perPage: 20,

      groups: [],
      groupMap: {},
      fields: {
        list: [
//          {key: 'selected', class: 'w-65px'},
          {key: 'no', label: 'No'},
          {key: 'name', label: '소재명(영문)'},
          {key: 'name_ko', label: '소재명(한글)'},
          {key: 'html1', label: '소재 그룹'},
          {key: '_cdt', label: '최초생성일시', class: 'text-center w-160px'},
          {
            key: '_actions', label: '기능', class: 'text-center w-180px', buttons: [
              {label: '상세', event: 'json'},
              {label: '삭제', variant: 'danger', event: 'remove'},
            ]
          },
        ]
      },
    };
  },
  async created() {
    this.resetForm('list');
    this.resetForm('add');
    this.resetForm('addGroup');
    await this.fetchGroup();
    this.list();
  },
  methods: {
    async fetchGroup() {
      const j = await this.$api.postJson('/meta/master/composition/group');
      this.groups = j.list;
      this.groupMap = this.$utils.arr2map(j.list, 'no', 'name_ko');
    },
    async list(more) {
      const form = this.form.list;
      await this.$api.postTable(this, '/meta/master/composition', form, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.group = this.groupMap[e.groupNo];
      e.html1 = `<span class="badge badge-light">${e.group}</span>`;
      return e;
    },

    /* 좌측버튼 부분 */

    showGroupModal() {
      this.modal.addGroup = true;
      this.form.addGroup.name_ko = '';
    },
    showModal() {
      this.modal.add = true;
      this.form.add.groupNo = '';
      this.form.add.name = '';
      this.form.add.name_ko = '';
    },
    async addGroup() {
      this.modal.addGroup = false;
      this.busy.addGroup = true;
      const j = await this.$api.postJson('/meta/master/composition/addGroup', this.form.addGroup);
      this.busy.addGroup = false;
      if (j) {
        await this.fetchGroup();
        this.list();
      }
    },
    async add() {
      this.modal.add = false;
      this.busy.add = true;
      const j = await this.$api.postJson('/meta/master/composition/add', {...this.form.add, groupNo: this.form.add.groupNo.no});
      this.busy.add = false;
      if (j) {
        this.list();
      }
    },

    /* 우측버튼 부분 */

    downXlsx() {
      const headers = 'No,소재명(영문),소재명(한글),소재 그룹,최초생성일시'.split(',');
      let fields = 'no,name,name_ko,group,_cdt'.split(',');
      down(this.items.list, headers, fields, `MasterComposition`, 'xlsx');
    },

    /* 리스트 부분 */

    async btnAction(row, event) {
      if (event === 'json') {
        this.$modal.show({title: 'JSON 보기', type: 'json', item: row.item});
      } else if (event === 'remove') {
        if (!confirm(`${row.item.name}(${row.item.name_ko}) 소재를 삭제하시겠습니까?`)) return;
        this.busy.remove = true;
        const j = await this.$api.postJson('/meta/master/composition/remove', {no: row.item.no});
        this.busy.remove = false;
        if (j) {
          this.list();
        }
      }
    },
    async removeMany() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 소재명을 선택해 주시기 바랍니다.');

      if (!confirm(`${selected.length}개의 소재명을 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/master/composition/removeMany', {nos: selected.map(e => e.no)});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    resetForm(name) {
      Vue.set(this.form, name, this.$utils.clone(this.defaultForm[name]));
    }
  },
}
</script>
