<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <category-preset v-model="form.list.cates" v-bind="{hideOptions: true, finalCate: 'only'}"></category-preset>
      </b-col>
    </b-row>
    <div class="clearfix mt-2 mb-2">
      <b-button @click="list()" class="mr-1" variant="primary">검색</b-button>
      <div class="pull-right">
        <b-button class="mr-1" variant="success" @click="downXlsx()">매칭 Xlsx Down</b-button>
        <b-button variant="outline-success" @click="() => {$refs.xlsxUpload.value = null; $refs.xlsxUpload.click()}">매칭 Xlsx Upload</b-button>
        <input type="file" ref="xlsxUpload" data-type="mapping" style="display: none" @change="handleXlsx">
      </div>
    </div>

    <c-table id="gmFeature" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 데이터'" @get-more="list(true)">
    </c-table>
  </div>
</template>
<script>

import Vue from 'vue';
import {down, readXlsx} from '@/shared/impexp'

export default {
  name: 'MasterMetaFeature',
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          cates: [],
        },
      },
      form: {
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, mapping: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {},
      perPage: 20,

      fields: {
        list: [
//          {key: 'selected', class: 'w-65px'},
          {key: 'category', label: '카테고리 코드'},
          {key: 'cate_path', label: '카테고리 경로'},
          {key: 'html1', label: '매핑된 상품 특징'},
          {key: '_cdt', label: '최초생성일시', class: 'text-center w-160px'},
          {
            key: '_actions', label: '기능', class: 'text-center w-180px', buttons: [
              {label: '상세', event: 'json'},
              {label: '삭제', variant: 'danger', event: 'remove'},
            ]
          },
        ]
      },
      validator: {
        mapping: {
          '최종 카테고리 코드': {test: /^(\d{3}){3,4}$/, type: 'string', required: true},
          '상품 특징 번호': {test: /^\d+$/, type: 'number', required: true},
        },
      }
    };
  },
  async created() {
    this.resetForm('list');
    this.list();
  },
  methods: {
    async list(more) {
      const form = this.form.list;
      const cates = form.cates.map(e => e.category);
      await this.$api.postTable(this, '/meta/master/featureMapping', {...form, cates}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.html1 = e.features.map(f => `${f.no}. ${f.name}`).join('<br/>');
      return e;
    },

    /* 우측버튼 부분 */

    downXlsx() {
      const headers = '최종 카테고리 코드,카테고리 경로,최초생성일시,상품 특징 번호,상품 특징(영문),상품 특징(한글)'.split(',');
      const fields = 'category,cate_path,_cdt,no,name,name_ko'.split(',');
      const list = this.items.list.map(e => {
        e.features.forEach(f => {
          Object.assign(f, {category: e.category, cate_path: e.cate_path, _cdt: e._cdt});
        });
        return e.features;
      }).flat();
      down(list, headers, fields, `MasterFeatureMapping`, 'xlsx');
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');

      const {headers, rows} = await readXlsx(file);
      const type = event.target.dataset.type;
      this.uploadXlsx(event.target, headers, rows, type);
    },
    async uploadXlsx(target, headers, rows, type) {
      const vMap = this.validator[type];

      const required = Object.keys(vMap).filter(e => !headers.includes(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n' + required.join('\n'));

      const wrongRows = [];
      rows.forEach((row, i) => {
        const wrongCols = [];
        headers.filter(h => vMap[h]).forEach(h => {
          const tester = vMap[h].test;
          if ((row[h] != null && row[h] !== '') && tester && !tester.test(row[h])) {
            wrongCols.push(`${h}: ${row[h]}`);
          }
          if ((row[h] == null || row[h] === '') && vMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 1} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      const typeHeaders = headers.filter(h => vMap[h] && vMap[h].type);
      rows.forEach(row => {
        typeHeaders.forEach(h => {
          const type = vMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = '' + row[h];
          } else if (type === 'boolean') {
            if (row[h] && typeof row[h] !== 'boolean') {
              if (row[h].match(/^true$/i)) {
                row[h] = true;
              } else if (row[h].match(/^false$/i)) {
                row[h] = false;
              } else {
                return alert('TRUE / FALSE 값이 맞게 입력되었는지 확인해주세요: ' + row[h]);
              }
            }
          }
        });
      });

      this.busy[type] = true;
      const j = await this.$api.postJson('/meta/master/featureMapping/xlsxUpload', {rows});
      this.busy[type] = false;
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt} 건 정상적으로 업로드 되었습니다`);
        this.list();
      } else if (j.ok === -1) {
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + `<h4>${j.msg}</h4>` + '</pre>'});
      }
      target.value = '';
    },

    /* 리스트 부분 */

    async btnAction(row, event) {
      if (event === 'json') {
        this.$modal.show({title: 'JSON 보기', type: 'json', item: row.item});
      } else if (event === 'remove') {
        if (!confirm(`${row.item.cate_path}(${row.item.category}) 의 특징 매핑을 삭제하시겠습니까?`)) return;
        this.busy.remove = true;
        const j = await this.$api.postJson('/meta/master/featureMapping/remove', {category: row.item.category});
        this.busy.remove = false;
        if (j) {
          this.list();
        }
      }
    },
    resetForm(name) {
      Vue.set(this.form, name, this.$utils.clone(this.defaultForm[name]));
    }
  },
}
</script>
